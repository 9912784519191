<template>
    <div class="StockCountView">
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name" @show_pp="show_popup = true"></nav-bar-component>
        <van-popup
            v-model="show_popup"
            position="top"
            round
            :close-on-click-overlay="false"
            closeable
        >
            <van-form :key="form_key">
                <div class="search_title">
                    <van-tag round type="success" size="large">条件搜索区</van-tag>
                </div>
                <van-field
                    v-model="cond.name"
                    name="name"
                    label="产品名称"
                    placeholder="产品名称"
                />
                <select-component
                    name="client_id"
                    lable="所属客户"
                    :farr="all_client"
                    @clk="confirm_client"
                ></select-component>
                <select-component
                    name="enterprise_id"
                    lable="所属企业"
                    :farr="all_enterprise"
                    @clk="confirm_enterprise"
                ></select-component>
                <select-component
                    name="storeroom_id"
                    lable="所属仓库"
                    :farr="all_storeroom"
                    @clk="confirm_storeroom"
                ></select-component>
                <select-component
                    name="location_id"
                    lable="所在库位"
                    :farr="all_localtion"
                    @clk="confirm_location"
                ></select-component>
                <div class="search_button">
                    <van-button round block type="info" icon="search" @click="search">搜 索</van-button>
                </div>
                <div class="search_button">
                    <van-button round block type="warning" @click="reset">重 置</van-button>
                </div>
                <div class="search_button">
                    <van-button round block type="default" @click="show_popup = false">取 消</van-button>
                </div>
            </van-form>
        </van-popup>
        <van-list
            v-model="list_loading"
            :finished="finished"
            finished-text="我是有底线的"
            @load="onLoad"
        >
            <van-cell-group inset v-for="v in list" :key="v.id+v.name">
                <van-cell>
                    <template #title>
                        <span class="title_span">{{v.product_name}}</span>
                    </template>
                </van-cell>
                <van-cell class="note_cell" title="产品规格" :value="v.specifications" :border="false" />
                <van-cell class="note_cell" title="总重量" :value="v|div_weight" :border="false"><template #right-icon><span>KG</span></template></van-cell>
                <van-cell class="note_cell" title="托盘数" :value="v.count_tray_num" :border="false"><template #right-icon><span>个</span></template></van-cell>
                <van-cell class="note_cell" title="所属客户" :value="v.with_client_name" :border="false" />
            </van-cell-group>
        </van-list>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'

import { enterprise_list_request,storeroom_list_request,client_list_request,location_list_request } from '@/network/list.js'
import { stock_count_request } from '@/network/WareHouse/stock'

import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name:'StockCountView',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: 'text',
                    name: '搜索'
                },
            },
            my_nav_bar_name: '库存统计',
            list: [],
            total: 0,
            list_loading: false,
            finished: false,
            show_popup: false,
            form_key: Date.now(),
            cond: {
                name: '',
                client_id: '',
                enterprise_id: '',
                storeroom_id: '',
                location_id: '',
                page: 1
            },
            all_client: [],
            all_enterprise: [],
            all_storeroom: [],
            all_localtion: []
        }
    },
    computed:{},
    methods:{
        get_all_client() {
            this.$store.commit('true_loading')
            client_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_client = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_all_enterprise() {
            this.$store.commit('true_loading')
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_enterprise = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_all_storeroom(enterprise) {
            this.$store.commit('true_loading')
            storeroom_list_request({enterprise_id: enterprise})
                .then((s) => {
                    if (s.status === 0) {
                        this.all_storeroom = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_all_location(storeroom) {
            this.$store.commit('true_loading')
            location_list_request({storeroom_id: storeroom})
                .then((s) => {
                    if (s.status === 0) {
                        this.all_localtion = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_client(e) {
            this.cond.client_id = e.id
        },
        confirm_enterprise(e) {
            this.cond.enterprise_id = e.id
            this.get_all_storeroom(e.id)
        },
        confirm_storeroom(e) {
            this.cond.storeroom_id = e.id
            this.get_all_location(e.id)
        },
        confirm_location(e) {
            this.cond.location_id = e.id
        },
        search() {
            this.cond.page = 1
            this.get_stock_count()
            this.show_popup = false
        },
        reset() {
            this.cond = {
                name: '',
                client_id: '',
                enterprise_id: '',
                storeroom_id: '',
                location_id: '',
                page: 1
            }
            this.form_key =  Date.now()
        },
        onLoad() {
            setTimeout(() => {
                this.cond.page++
                stock_count_request(this.cond)
                    .then((s) => {
                        if (s.status === 0) {
                            this.total = s.result.total
                            s.result.data.forEach(v => {
                                this.list.push(v)
                            });
                        } else {
                            this.$toast.fail(s.msg);
                        }
                    })
                    .catch((err) => {
                        console.log(s.msg)
                    })
                    .finally(() => {
                        // 加载状态结束
                        this.list_loading = false;

                        // 数据全部加载完成
                        if (this.list.length >= this.total) {
                            this.finished = true;
                        }
                    })
            }, 500);
        },
        get_stock_count() {
            this.list = []
            this.finished = false
            this.$store.commit('true_loading')
            stock_count_request(this.cond)
                .then((s) => {
                    if (s.status === 0) {
                        this.list = s.result.data
                        this.total = s.result.total
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(s.msg)
                })
                .finally(() => this.$store.commit('false_loading'))
        }
    },
    filters:{
        div_weight(v) {
            return thousandBitSeparator(fomatFloat(v.count_weight))
        }
    },
    props:{},
    created(){
        this.get_all_enterprise()
        this.get_all_client()
        this.get_stock_count()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent
    },
    watch:{}
}
</script>

<style>
.StockCountView .van-cell-group {
    margin-bottom: 0.2rem;
}
.StockCountView .title_span {
    font-weight: bold;
    color: #0080FF;
}
.StockCountView .note_cell {
    padding: 0.1rem 0.42667rem
}
.StockCountView .search_title {
    text-align: center;
}
.StockCountView .search_button {
    margin: 0.5rem;
}
.StockCountView .insert {
    text-align: center;
    /* position: absolute; */
        transform: translateY(-40%);
}
</style>